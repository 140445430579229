/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
    Tag,
    Tooltip,
} from "@jhool-io/fe-components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { Document, Page } from "react-pdf";
import { IInsurancePayments, IRemit } from "../../types/remits.types";
import {
    buildSuccessMessage,
    cn,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    handleRemoveEmptyParamFromQueryParams,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import {
    useFetchSingleRemitDocuments,
    useFetchSingleRemitEOB,
} from "../../hooks/remits.queries";
import ListState from "../../../../components/ListState/ListState";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";
import { useGetInvoicePayment } from "../../../../hooks/queries/billing";
import useToast from "../../../../hooks/useToast";
import {
    useDeleteRemitDocument,
    usePostPayments,
} from "../../hooks/remits.mutations";
import ZoomInIcon from "../../../../components/Icons/ZoomIn";
import ZoomOutIcon from "../../../../components/Icons/ZoomOut";
import InfoBlueIcon from "../../../../components/Icons/InfoBlue";
import UpdatePaymentForm, {
    ISelectedPayment,
} from "../UpdatePaymentForm/UpdatePaymentForm";
import FilePicker from "../../../../components/FilePicker/FilePicker";
import { SupportedUploadMethod } from "../../../../utils/types/filepicker";
import { useFetchDocument } from "../../../../hooks/queries";
import { ClientDocumentTypes } from "../../../../utils/types/client";
import TrashIcon from "../../../../components/Icons/Trash";
import ArrowRightIcon from "../../../../components/Icons/ArrowRight";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import { APP_COLORS } from "../../../../utils/constants";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import PaymentActionForm from "../../../billing/components/PaymentActionForm/PaymentActionForm";
import {
    PaymentModals,
    RemarkCodeDescription,
    WriteOffCodesDecription,
} from "../../../billing/types/billing.types";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import { UserPermisions } from "../../../../utils/types/user";
import { useUnMatchPayment } from "../../../../hooks/mutations/billing";
import { PaymentStatus } from "../../../payments/types/payments.types";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";

type RemitDocumentDialog = "add-doc" | "view-doc" | "delete-doc";

interface RemitProps {
    remitData: IRemit;
}

// TODO - Reduce modal states in this file

export default function Remit({ remitData }: RemitProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalInView, setModalInView] = React.useState<PaymentModals | null>(
        null
    );
    const [documentDialogInView, setDocumentDialogInView] =
        React.useState<RemitDocumentDialog | null>(null);
    const [documentInViewId, setDocumentInViewId] = React.useState<
        string | null
    >(null);
    const [EOBpageNumber, setEOBPageNumber] = React.useState(1);
    const [EOBNumPages, setEOBNumPages] = React.useState<null | number>(null);
    const [documentPageNumber, setDocumentPageNumber] = React.useState(1);
    const [documentNumPages, setDocumentNumPages] = React.useState<
        null | number
    >(null);
    const [scale, setScale] = React.useState(3);

    const [showDialog, setShowDialog] = React.useState(false);
    const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);
    const [selectedPayment, setSelectedPayment] =
        React.useState<ISelectedPayment | null>(null);
    const [writeOffAction, setWriteOffAction] = React.useState<
        "move" | "close" | null
    >(null);
    const [showDeletePaymentDialog, setShowDeletePaymentDialog] =
        React.useState(false);

    const [documentToDeleteId, setDocumentToDeleteId] = React.useState<
        string | null
    >(null);

    const [paymentId, setPaymentId] = React.useState<string | null>(null);
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IInsurancePayments>[]>();
    const [showBulkPostPaymentDialog, setShowBulkPostPaymentDialog] =
        React.useState(false);

    const params = useParams();
    const { claimId } = params;

    const handlePaymentToPost = (id: string) => {
        setPaymentId(id);
    };

    // payload to get invoice
    const getInvoicePayload = {
        invoice_id: remitData ? remitData.invoice_id : "",
        payment_type: "i",
    };

    // get payment invoice data
    const { data: invoiceData } = useGetInvoicePayment(
        getInvoicePayload.invoice_id,
        getInvoicePayload,
        Boolean(remitData) && Boolean(remitData.invoice_id)
    );

    // get remitEOB
    const singleRemitEOB = useFetchSingleRemitEOB(
        remitData ? remitData.remittance_id : "",
        Boolean(remitData) && Boolean(remitData.remittance_id)
    );

    const deleteDocument = useDeleteRemitDocument(
        remitData ? remitData.remittance_id : "",
        documentToDeleteId || ""
    );

    const singleRemitFiles = useFetchSingleRemitDocuments(
        remitData ? remitData.remittance_id : "",
        Boolean(remitData)
    );

    const insurancePaymentIsMutating = useIsMutating([
        "add-payment",
        remitData ? remitData.invoice_id : "",
    ]);

    const selectedDocumentDetails = singleRemitFiles.data?.data?.find(
        (item) => item.remittance_attachment_id === documentInViewId
    );

    const remitFile = useFetchDocument(
        selectedDocumentDetails?.url as string,
        Boolean(documentInViewId) && Boolean(selectedDocumentDetails)
    );

    const showBulkPaymentsSelectAllCheckbox =
        remitData?.insurance_payments?.filter(
            (payment) => payment.payment_status === PaymentStatus.PENDING
        )?.length > 0 || false;

    const confirmPaymentIsMutating = useIsMutating(["post-payment", paymentId]);
    const isUpdatingPayment = useIsMutating(["update-payment"]);

    const handleModalInView = (modal: PaymentModals | null) => {
        if (modal !== null) searchParams.set("payment_type", "insurance");
        else searchParams.delete("payment_type");
        setModalInView(modal);
        setSearchParams(searchParams);
    };

    // check if logged in user can unmatch payments
    const canDeletePayments = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
    ]);

    const zoomIn = () => {
        if (scale < 3) {
            setScale((prevScale: number) => prevScale + 0.5);
        }
    };

    const zoomOut = () => {
        if (scale > 1) {
            setScale((prevScale: number) => prevScale - 0.5);
        }
    };

    const getInsurancePaymentModalHeader = () => {
        switch (modalInView) {
            case "write_off":
                return "Write Off";
            case "payment":
                return "Add insurance";
            default:
                return "Add refund";
        }
    };

    const getInsurancePaymentModalSaveText = () => {
        switch (modalInView) {
            case "write_off":
                return "Add write-off";
            case "payment":
                return "Add payment";
            case "refund":
                return "Add refund";
            default:
                return "Add payment";
        }
    };

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const getRemarkCodeDescription = (code: string): string => {
        const matchingDescription = RemarkCodeDescription[code.toLowerCase()];
        return matchingDescription || "--";
    };

    function getPaymentTypeTag(
        type: string,
        method: string,
        info?: {
            refund_reason?: string;
            cheque_number?: string;
            authorization_number?: string;
        },
        writeoff_code?: string,
        auth_code?: string
    ) {
        switch (type) {
            case "wbi":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={
                                <>
                                    Write-off:{" "}
                                    <span className="uppercase">
                                        {writeoff_code}
                                    </span>
                                </>
                            }
                        />
                        <Tooltip
                            content={getWriteOffCodeDescription(
                                writeoff_code || "--"
                            )}
                            classNames="uppercase"
                        >
                            <Button variant="normal" size="auto">
                                <InfoIcon />
                            </Button>
                        </Tooltip>
                    </div>
                );
            case "ri":
            case "rpr100":
                return (
                    <Tag
                        bgColor="rgba(204, 250, 233, 0.50)"
                        textColor="#00563E"
                        className="font-normal px-8 py-4 h-24 rounded-r4 whitespace-nowrap"
                        title={
                            <>
                                Refund:{" "}
                                {info?.refund_reason
                                    ? removeEnumUnderscore(info?.refund_reason)
                                    : "Others"}
                            </>
                        }
                    />
                );
            case "i":
            case "ci":
            case "pr100":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(247, 229, 164, 0.50)"
                            textColor="#634D1"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={<>Credit: {removeEnumUnderscore(method)}</>}
                        />
                        {info?.cheque_number && (
                            <Tooltip
                                content={`CHEQUE NUMBER - ${info.cheque_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {auth_code && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${auth_code}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {info?.authorization_number && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${info.authorization_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
            case "pi":
                return (
                    <Tag
                        bgColor="rgba(172, 222, 250, 0.50)"
                        textColor="#165574"
                        className="font-normal px-8 py-4 h-24 rounded-r4"
                        title="Patient insurance"
                    />
                );

            default:
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={type ?? "others"}
                        />
                        {info?.refund_reason && (
                            <Tooltip
                                content={info.refund_reason}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
        }
    }

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const postPayments = usePostPayments();

    const deletePayment = useUnMatchPayment();

    // reused css classes for remit payment summary section
    const summaryWrapperClass =
        "mb-16 last:mb-0 text-xs font-medium flex items-center gap-x-12";
    const summaryBottomWrapperClass =
        "mb-12 last:mb-0 text-xs font-medium flex items-center gap-x-12";
    const summaryItemRightClass =
        "ml-auto flex items-center gap-x-12 font-semibold w-[calc(350px-24px)] *:flex-1";
    const summaryBottomItemRightClass =
        "ml-auto font-semibold w-[169px] pl-24 text-left";

    const remitPaymentSummaryOutstanding =
        remitData.insurance_charged -
        remitData.pr100_collected -
        (remitData.insurance_paid +
            remitData.insurance_writeoff +
            remitData.patient_paid +
            remitData.patient_writeoff);

    const handlePostPayment = (id: string) => {
        postPayments.mutate(
            { payment_ids: [id] },
            {
                onSuccess: (res) => {
                    queryClient.invalidateQueries([
                        "remit-claims",
                        {
                            note_id: remitData.note_id,
                        },
                    ]);
                    toast({
                        mode: "success",
                        message:
                            res.message || "Payment(s) posted successfully.",
                    });
                    setShowDialog(false);
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not post payment(s).",
                    });
                },
            }
        );
    };

    const handleBulkPostPayments = () => {
        const paymentIdsToPost =
            (getSelectedData &&
                getSelectedData.map(
                    (payment) => payment.original.payment_id
                )) ||
            [];
        postPayments.mutate(
            { payment_ids: paymentIdsToPost },
            {
                onSuccess: (res) => {
                    queryClient.invalidateQueries([
                        "remit-claims",
                        {
                            note_id: remitData.note_id,
                        },
                    ]);
                    toast({
                        mode: "success",
                        message: res.message || "Payments posted successfully.",
                    });
                    setShowBulkPostPaymentDialog(false);
                    setRowSelection({});
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not post payment(s).",
                    });
                },
            }
        );
    };

    const handleCloseUpdatePaymentDialog = () => {
        setShowPaymentDialog(false);
        setSelectedPayment(null);
    };

    const handleDeleteDocument = () => {
        deleteDocument.mutate(null, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["remit-docs", remitData.remittance_id],
                });
                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Insurance attachment deleted successfully!",
                });
                setDocumentToDeleteId(null);
                setDocumentDialogInView(null);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete remit document at this time",
                });
            },
        });
    };

    // function to unmatch payment
    const handleDeletePayment = (id: string) => {
        const deletePaymentPayload = {
            matched_payments: {
                [remitData.invoice_id]: [id],
            },
        };
        deletePayment.mutate(deletePaymentPayload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries([
                    "remit-claims",
                    {
                        note_id: remitData.note_id,
                    },
                ]);

                queryClient.invalidateQueries({
                    queryKey: [
                        "get-claim",
                        handleRemoveEmptyParamFromQueryParams({
                            claim_id: claimId,
                        }),
                    ],
                });

                setShowDeletePaymentDialog(false);
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.successful_payments,
                        response.data.failed_payments
                    )}`,
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete payment at this time",
                });
            },
        });
    };

    const insuranceColumns: ColumnDef<IInsurancePayments>[] = [
        {
            id: "select",
            header: ({ table }) =>
                showBulkPaymentsSelectAllCheckbox ? (
                    <Checkbox
                        handleChange={table.getToggleAllRowsSelectedHandler()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        label=""
                        name="row"
                        value="row"
                        isChecked={table.getIsAllRowsSelected()}
                    />
                ) : null,
            cell: ({ row }) =>
                row.original.payment_status === PaymentStatus.PENDING ? (
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        id={row.original.payment_id}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                ) : null,
        },
        {
            accessorKey: "payment_date",
            header: "PAYMENT DATE",
            cell: ({ row }) =>
                formatDate(row.original.payment_date, true) || "--",
        },
        {
            accessorKey: "payment_type",
            header: "PAYMENT TYPE",
            cell: ({ row }) =>
                getPaymentTypeTag(
                    row.original.payment_type,
                    row.original.payment_method,
                    row.original.payment_info,
                    row.original.writeoff_code,
                    row.original.auth_code
                ),
        },
        {
            accessorKey: "amount",
            header: "AMOUNT",
            cell: ({ row }) => (
                <div className="flex items-center gap-8">
                    {showMoneyInAppFormat(row.original.amount) || "--"}
                </div>
            ),
        },

        {
            accessorKey: "payment_status",
            header: "PAYMENT STATUS",
            cell: ({ row }) => {
                let bgColor: string;
                let textColor: string;

                switch (row.original.payment_status) {
                    case "completed":
                        bgColor = "rgba(204, 250, 233, 0.50)";
                        textColor = "#00563E";
                        break;
                    case "failed":
                        bgColor = "rgba(251, 199, 198, 0.50)";
                        textColor = "#981F41";
                        break;
                    default:
                        bgColor = "rgba(247, 229, 164, 0.50)";
                        textColor = "#634D17";
                }

                return (
                    <Tag
                        bgColor={bgColor}
                        textColor={textColor}
                        title={makeStringFirstLetterCapital(
                            row.original.payment_status
                        )}
                        className="capitalize rounded-r4 px-8 h-24 text-xs py-4"
                    />
                );
            },
        },
        {
            accessorKey: "remark_code",
            header: "REMARK CODE",
            cell: ({ row }) =>
                row.original.remark_codes &&
                row.original.remark_codes.length > 0 ? (
                    <div className="flex gap-x-4 gap-y-8 flex-wrap items-center">
                        {row.original.remark_codes.map((code) => (
                            <div
                                key={code}
                                className="flex items-center gap-x-4"
                            >
                                <Tag
                                    bgColor="#F3F9FE"
                                    textColor="#059"
                                    className="uppercase flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                                    title={code}
                                />
                                <Tooltip
                                    content={getRemarkCodeDescription(code)}
                                >
                                    <Button variant="normal" size="auto">
                                        <InfoIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                ) : (
                    "--"
                ),
        },
        {
            accessorKey: "eft_number",
            header: "EFT",
            cell: ({ row }) =>
                row.original.eft_number ? (
                    <Link
                        to={`/remit/EOB/${remitData.remittance_id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary text-sm underline"
                    >
                        {row.original.eft_number}
                    </Link>
                ) : (
                    "--"
                ),
        },
        {
            accessorKey: "date_posted",
            header: "DATE POSTED",
            cell: ({ row }) => formatDate(row.original.date_posted) || "--",
        },
        {
            accessorKey: "posted_by",
            header: "POSTED BY",
            cell: ({ row }) =>
                Object.keys(row.original.posted_by).length > 0
                    ? `${row.original.posted_by.first_name} ${row.original.posted_by.last_name}`
                    : "Mantle",
        },
        {
            accessorKey: "payment_notes",
            header: "PAYMENT NOTES",
            cell: ({ row }) => row.original.payment_notes || "--",
        },
        {
            accessorKey: "payment_id",
            header: "",
            cell: ({ row }) => {
                return row.original.payment_type === "wbi" ||
                    (row.original.payment_type !== "wbi" &&
                        row.original.payment_status !== "completed") ||
                    canDeletePayments ? (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton />
                        </DropdownTrigger>

                        <DropdownContent width="auto" align="end">
                            {row.original.payment_status !== "completed" && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDialog(true);
                                        handlePaymentToPost(
                                            row.original.payment_id
                                        );
                                    }}
                                >
                                    Confirm Payment
                                </DropdownItem>
                            )}

                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedPayment(row.original);
                                    setShowPaymentDialog(true);
                                }}
                            >
                                Edit Payment
                            </DropdownItem>

                            {canDeletePayments && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDeletePaymentDialog(true);
                                        setPaymentId(row.original.payment_id);
                                    }}
                                >
                                    Delete Payment
                                </DropdownItem>
                            )}
                        </DropdownContent>
                    </Dropdown>
                ) : null;
            },
        },
    ];

    const fileToDisplay = () => {
        if (
            selectedDocumentDetails &&
            /^image/.test(selectedDocumentDetails?.metadata?.content_type || "")
        ) {
            return (
                <>
                    {remitFile.isLoading && (
                        <span className=" text-sm flex items-center justify-center font-medium h-12">
                            Loading document....
                        </span>
                    )}
                    {remitFile.error && (
                        <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                            Could not load document at this time
                        </span>
                    )}
                    {remitFile.data && (
                        <div className="h-full w-full">
                            <img
                                className="w-full h-full object-contain"
                                src={`data:image/png;base64,${remitFile.data?.data}`}
                                alt={selectedDocumentDetails?.name}
                            />
                        </div>
                    )}
                </>
            );
        }

        return (
            <Document
                error={
                    <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                        Could not load pdf at this time
                    </span>
                }
                loading={
                    <span className=" text-sm flex items-center justify-center font-medium h-12">
                        Loading pdf..
                    </span>
                }
                onLoadSuccess={({ numPages: nextNumPages }) => {
                    setDocumentNumPages(nextNumPages);
                    setDocumentPageNumber(1);
                }}
                onItemClick={({ pageNumber: itemPageNumber }) => {
                    setDocumentPageNumber(Number(itemPageNumber));
                }}
                file={`data:image/png;base64,${remitFile.data?.data}`}
            >
                <Page
                    height={550}
                    scale={scale}
                    pageNumber={documentPageNumber}
                />
                {documentNumPages && documentNumPages > 1 && (
                    <Pagination
                        totalCount={documentNumPages}
                        totalPages={documentNumPages}
                        currentPage={documentPageNumber}
                        onPageChange={(page) => setDocumentPageNumber(page)}
                        pageSize={1}
                        isCentered
                    />
                )}
            </Document>
        );
    };

    const handleCloseDialog = () => {
        handleModalInView(null);
        setWriteOffAction(null);
        searchParams.delete("writeoff_type");
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    return (
        <>
            <Dialog open={showBulkPostPaymentDialog}>
                <DialogContent
                    title="Confirm Payments"
                    saveText="Confirm Payments"
                    variant="center"
                    handleCloseDialog={() =>
                        setShowBulkPostPaymentDialog(false)
                    }
                    onSaveClick={handleBulkPostPayments}
                    isSubmitBtnDisabled={postPayments.isLoading}
                    isCancelBtnDisabled={postPayments.isLoading}
                >
                    <h3 className="font-medium text-xl mb-8">
                        Are you sure you want to confirm these payments?
                    </h3>
                </DialogContent>
            </Dialog>
            {remitData && (
                <Dialog
                    open={
                        modalInView === "payment" ||
                        modalInView === "write_off" ||
                        modalInView === "refund"
                    }
                >
                    <DialogContent
                        handleCloseDialog={handleCloseDialog}
                        variant="center"
                        title={getInsurancePaymentModalHeader()}
                        saveText={
                            searchParams.get("writeoff_type") === "insurance"
                                ? "Save & Hold"
                                : getInsurancePaymentModalSaveText()
                        }
                        submitBtnFormValue="action-form"
                        isSubmitBtnDisabled={insurancePaymentIsMutating > 0}
                        isCancelBtnDisabled={insurancePaymentIsMutating > 0}
                        canCancelButtonSubmit
                        onCancelClick={() => {
                            if (
                                searchParams.get("writeoff_type") ===
                                "insurance"
                            ) {
                                setWriteOffAction("move");
                            } else setModalInView(null);
                        }}
                        cancelBtnFormValue="writeoff-move-form"
                        cancelText={
                            searchParams.get("writeoff_type") === "insurance"
                                ? "Save & Move"
                                : undefined
                        }
                        backBtnFormValue="writeoff-close-form"
                        canBackButtonSubmit
                        showBackBtn={
                            searchParams.get("writeoff_type") === "insurance"
                        }
                        backText="Save & Close Bill"
                        onBackClick={() => {
                            if (
                                searchParams.get("writeoff_type") ===
                                "insurance"
                            ) {
                                setWriteOffAction("close");
                            }
                        }}
                    >
                        <>
                            <h3 className="text-sm mb-16 font-semibold">
                                CLIENT DETAILS
                            </h3>
                            <div>
                                <div className="border-b border-b-strokedark pb-[30px] grid grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] mb-16">
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            CLIENT NAME
                                        </p>
                                        <Link
                                            to={`/clients/${remitData.client.client_id}`}
                                            className="underline text-primary text-sm capitalize"
                                        >
                                            {`${remitData?.client.first_name} ${remitData?.client.first_name}`}
                                        </Link>
                                    </div>
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            INSURANCE PROVIDER
                                        </p>
                                        <p className="text-sm uppercase">
                                            {remitData.insurance_provider_name ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-y-4 items-start">
                                        <p className="text-gray text-xs uppercase font-medium">
                                            INSURANCE BALANCE
                                        </p>
                                        {invoiceData?.accounting ? (
                                            <p
                                                className={cn(
                                                    "text-sm uppercase",
                                                    {
                                                        "text-danger":
                                                            invoiceData?.accounting >
                                                            0,
                                                    }
                                                )}
                                            >
                                                {invoiceData?.accounting > 0
                                                    ? `(${showMoneyInAppFormat(
                                                          invoiceData?.accounting
                                                      )})`
                                                    : showMoneyInAppFormat(
                                                          Math.abs(
                                                              invoiceData?.accounting
                                                          )
                                                      )}
                                            </p>
                                        ) : (
                                            "--"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <PaymentActionForm
                                invoiceId={remitData.invoice_id || ""}
                                clientId={remitData.client.client_id}
                                onFormSubmit={() => handleModalInView(null)}
                                modalType={modalInView}
                                writeOffAction={writeOffAction}
                            />
                        </>
                    </DialogContent>
                </Dialog>
            )}

            <Dialog
                open={
                    documentDialogInView === "add-doc" ||
                    documentDialogInView === "view-doc"
                }
            >
                <DialogContent
                    handleCloseDialog={() => {
                        setDocumentDialogInView(null);
                    }}
                    variant={
                        documentDialogInView === "add-doc" ? "center" : "drawer"
                    }
                    showFooter={false}
                    title={
                        documentDialogInView === "add-doc"
                            ? "Upload document"
                            : ""
                    }
                >
                    {documentDialogInView === "add-doc" ? (
                        <FilePicker
                            name="filepicker"
                            uploadMethods={[
                                {
                                    method: SupportedUploadMethod.manualupload,
                                },
                                {
                                    method: SupportedUploadMethod.googledrive,
                                },
                                {
                                    method: SupportedUploadMethod.dropbox,
                                },
                            ]}
                            uploadType="remit-file"
                            successCallback={() => {
                                setDocumentDialogInView(null);
                            }}
                            documentToUploadType={ClientDocumentTypes.OTHER}
                            remitId={remitData.remittance_id}
                            remitName={`${remitData.client.first_name} ${remitData.client.first_name}`}
                        />
                    ) : (
                        fileToDisplay()
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={documentDialogInView === "delete-doc"}>
                <DialogContent
                    title="Delete document"
                    saveText="Delete document"
                    cancelText="Cancel"
                    handleCloseDialog={() => {
                        setDocumentDialogInView(null);
                    }}
                    variant="center"
                    onSaveClick={handleDeleteDocument}
                    isDeleting
                    isSubmitBtnDisabled={deleteDocument.isLoading}
                    isCancelBtnDisabled={deleteDocument.isLoading}
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete this document?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            By deleting this document, you won’t be able to
                            recover it.
                        </p>
                    </>
                </DialogContent>
            </Dialog>

            {selectedPayment && (
                <Dialog open={showPaymentDialog}>
                    <DialogContent
                        handleCloseDialog={handleCloseUpdatePaymentDialog}
                        variant="center"
                        title="Update payment"
                        submitBtnFormValue="update-payment"
                        isSubmitBtnDisabled={isUpdatingPayment > 0}
                        isCancelBtnDisabled={isUpdatingPayment > 0}
                    >
                        <UpdatePaymentForm
                            onFormSubmit={handleCloseUpdatePaymentDialog}
                            selectedPayment={selectedPayment}
                        />
                    </DialogContent>
                </Dialog>
            )}

            <Dialog open={Boolean(paymentId) && showDialog}>
                <DialogContent
                    handleCloseDialog={() => setShowDialog(false)}
                    variant="center"
                    classNames="p-24"
                    title="Confirm Payment"
                    saveText="Yes,confirm"
                    onSaveClick={() => handlePostPayment(paymentId as string)}
                    isCancelBtnDisabled={confirmPaymentIsMutating > 0}
                    isSubmitBtnDisabled={confirmPaymentIsMutating > 0}
                >
                    <h3 className="px-8 text-center font-medium text-lg mb-8">
                        Are you sure you want to confirm this payment?
                    </h3>
                </DialogContent>
            </Dialog>

            <Dialog open={Boolean(paymentId) && showDeletePaymentDialog}>
                <DialogContent
                    handleCloseDialog={() => setShowDeletePaymentDialog(false)}
                    isDeleting
                    variant="center"
                    classNames="p-24"
                    title="Delete Payment"
                    saveText="Delete Payment"
                    onSaveClick={() => handleDeletePayment(paymentId || "")}
                    isSubmitBtnDisabled={deletePayment.isLoading}
                    isCancelBtnDisabled={deletePayment.isLoading}
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to Delete this payment?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            <span>
                                Deleting this payment will remove it from your
                                records. <br />
                                You will no longer be able to see this payment.
                            </span>
                        </p>
                    </>
                </DialogContent>
            </Dialog>

            {remitData && (
                <Card classNames="px-24 py-12 bg-white rounded-r8 border border-[#c0c7cf33] overflow-x-auto">
                    <div className="min-w-[700px] max-w-full overflow-x-auto">
                        <div className="mb-12 text-sm font-bold flex items-center gap-x-12 ">
                            <span>Payment summary</span>
                            <div className="ml-auto flex items-center gap-x-12 font-semibold w-[350px]">
                                <span className="flex-1">Patient</span>
                                <span className="flex-1">Insurance</span>
                            </div>
                        </div>
                        <div className="px-8 py-12 rounded-r8 bg-card-bg">
                            <div className="px-16 py-12 bg-white rounded-r6 mb-4">
                                <div className={summaryWrapperClass}>
                                    <span>Charged</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_charged
                                            )}
                                        </span>
                                        <span className="pl-24">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_charged
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Expected</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_charged
                                            )}
                                        </span>
                                        <span className="pl-24 items-center flex gap-x-8">
                                            {remitData.insurance_expected
                                                ? showMoneyInAppFormat(
                                                      remitData.insurance_expected
                                                  )
                                                : "--"}
                                            {remitData.insurance_expected >
                                                remitData.insurance_paid && (
                                                <Tooltip
                                                    content="Expected amount is higher than paid amount"
                                                    classNames="gap-x-8"
                                                >
                                                    <Button
                                                        variant="normal"
                                                        size="auto"
                                                    >
                                                        <InfoCircleIcon
                                                            stroke={
                                                                APP_COLORS.COLOR_DANGER
                                                            }
                                                            fill={
                                                                APP_COLORS.COLOR_DANGER
                                                            }
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>UCR</span>
                                    <div className={summaryItemRightClass}>
                                        <span />
                                        <span className="items-center flex gap-x-8 pl-24">
                                            {remitData.ucr ?? "--"}
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Paid</span>
                                    <div className={summaryItemRightClass}>
                                        <span className="text-[#059] flex items-center gap-x-8">
                                            {showMoneyInAppFormat(
                                                remitData.patient_paid
                                            )}
                                            {remitData.patient_paid <
                                            remitData.patient_charged ? (
                                                <Tooltip
                                                    content="Paid amount is lower than charged amount"
                                                    classNames="gap-x-8"
                                                >
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                    >
                                                        <InfoBlueIcon />
                                                    </Button>
                                                </Tooltip>
                                            ) : null}
                                        </span>
                                        <span className="text-[#059] pl-24 items-center flex gap-x-8">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_paid
                                            )}
                                            <Tooltip
                                                content="Payments marked as ‘completed’ will appear here"
                                                classNames="gap-x-8"
                                            >
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                >
                                                    <InfoBlueIcon />
                                                </Button>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>PR100</span>
                                    <div className={summaryItemRightClass}>
                                        <span className="items-center flex gap-x-8">
                                            {remitData.pr100_collected ||
                                            remitData.pr100_collected === 0
                                                ? showMoneyInAppFormat(
                                                      remitData.pr100_collected
                                                  )
                                                : "--"}
                                        </span>
                                        <span className="pl-24" />
                                    </div>
                                </div>
                                <div className={summaryWrapperClass}>
                                    <span>Write-off</span>
                                    <div className={summaryItemRightClass}>
                                        <span>
                                            {showMoneyInAppFormat(
                                                remitData.patient_writeoff
                                            )}
                                        </span>
                                        <span className="pl-24">
                                            {showMoneyInAppFormat(
                                                remitData.insurance_writeoff
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-16 py-12 bg-white rounded-r6">
                                <div className={summaryBottomWrapperClass}>
                                    <span>INS Charged</span>
                                    <span
                                        className={summaryBottomItemRightClass}
                                    >
                                        {showMoneyInAppFormat(
                                            remitData.insurance_charged
                                        )}
                                    </span>
                                </div>
                                <div className={summaryBottomWrapperClass}>
                                    <span>PT Paid + INS Paid + Writeoff</span>
                                    <span
                                        className={summaryBottomItemRightClass}
                                    >
                                        {showMoneyInAppFormat(
                                            remitData.patient_paid +
                                                remitData.insurance_paid +
                                                remitData.patient_writeoff +
                                                remitData.insurance_writeoff
                                        )}
                                    </span>
                                </div>
                                <div className={summaryBottomWrapperClass}>
                                    <span
                                        className={
                                            remitPaymentSummaryOutstanding < 0
                                                ? "text-danger"
                                                : "text-primary"
                                        }
                                    >
                                        Outstanding
                                    </span>
                                    <span
                                        className={cn(
                                            `${summaryBottomItemRightClass} text-primary`,
                                            {
                                                "text-danger":
                                                    remitPaymentSummaryOutstanding <
                                                    0,
                                            }
                                        )}
                                    >
                                        {remitPaymentSummaryOutstanding < 0
                                            ? `(${showMoneyInAppFormat(
                                                  Math.abs(
                                                      remitPaymentSummaryOutstanding
                                                  )
                                              )})`
                                            : showMoneyInAppFormat(
                                                  remitPaymentSummaryOutstanding
                                              )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}

            {/* Insurance Payments Table Flow */}
            {remitData && (
                <Card classNames="p-24 mt-32">
                    <div className="flex justify-between items-center mb-24">
                        <p className="text-sm font-bold">Insurance payments</p>

                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button className="gap-x-8">
                                    Actions
                                    <ArrowDownIcon
                                        className="w-12"
                                        fill="#ffffff"
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent width="auto" align="end">
                                <DropdownItem
                                    onClick={() => handleModalInView("payment")}
                                >
                                    Add Insurance Payment
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        handleModalInView("write_off");
                                        searchParams.set(
                                            "writeoff_type",
                                            "insurance"
                                        );
                                        setSearchParams(searchParams);
                                    }}
                                >
                                    Writeoff
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => handleModalInView("refund")}
                                >
                                    Add Refund
                                </DropdownItem>
                            </DropdownContent>
                        </Dropdown>
                    </div>
                    <div className="mt-24">
                        {remitData &&
                            remitData.insurance_payments.length === 0 && (
                                <ListState
                                    stateHelperText="Insurance payments will appear here once they are added"
                                    isError={false}
                                    emptyMessage="No insurance payment added yet"
                                />
                            )}

                        {remitData && remitData.insurance_payments.length > 0 && (
                            <>
                                {getTotalAmountOfTableRowsSelected(rowSelection)
                                    .amount > 0 ? (
                                    <div className="flex items-center mb-16 gap-12 flex-wrap">
                                        <div className="font-semibold text-base">
                                            {
                                                getTotalAmountOfTableRowsSelected(
                                                    rowSelection
                                                ).amountString
                                            }{" "}
                                            :
                                        </div>
                                        <Button
                                            variant="secondary"
                                            disabled={postPayments.isLoading}
                                            onClick={() =>
                                                setShowBulkPostPaymentDialog(
                                                    true
                                                )
                                            }
                                        >
                                            Confirm Payments
                                        </Button>
                                    </div>
                                ) : null}
                                <Table
                                    columns={insuranceColumns}
                                    data={remitData.insurance_payments}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    setGetSelectedData={setGetSelectedData}
                                />
                            </>
                        )}
                    </div>
                </Card>
            )}

            {/* Adjustments Table Flow */}
            <div className="mt-32">
                {remitData &&
                    (remitData.claim_adjustments === null ||
                        (remitData.claim_adjustments &&
                            remitData.claim_adjustments.length === 0)) && (
                        <ListState
                            stateHelperText="Adjustments will appear here once they are added"
                            isError={false}
                            emptyMessage="No adjustments added yet"
                        />
                    )}

                {remitData &&
                    remitData.claim_adjustments &&
                    remitData.claim_adjustments.length > 0 && (
                        <Card classNames="p-24 overflow-x-auto max-w-full">
                            <p className="text-sm font-bold mb-24">
                                Adjustments
                            </p>

                            {/* adjustments header */}
                            <div className="flex items-center justify-between w-full text-gray text-xs font-semibold leading-7 gap-24 p-12 border-[1px] border-strokelight rounded-t-r8">
                                <p className="w-48">No.</p>

                                <p className="w-[392px]">GROUP</p>
                                <p className="w-[392px]">
                                    REASON DESCRIPTION (CODE)
                                </p>
                                <p className="w-[120px]">AMOUNT</p>
                                <p className="w-[120px]">QUANTITY</p>
                            </div>

                            {/* adjustments body */}
                            {remitData.claim_adjustments.map(
                                (adjustment, idx) => (
                                    <div
                                        key={
                                            adjustment.group_code +
                                            adjustment.reason_description
                                        }
                                        className="flex items-center justify-between w-full border-[1px] border-strokelight border-t-0 p-12 text-left
                            text-sm leading-8 gap-24
                            hover:bg-secondary-light
                            "
                                    >
                                        <p className="w-48">{idx + 1}</p>

                                        <p className="w-[392px]">
                                            {adjustment.group_code || "--"}
                                        </p>

                                        <p className="w-[392px]">
                                            {adjustment.reason_description ||
                                                "--"}
                                        </p>

                                        <p className="w-[120px]">
                                            {showMoneyInAppFormat(
                                                adjustment.amount
                                            ) || "--"}
                                        </p>

                                        <p className="w-[120px]">
                                            {adjustment.quantity || "--"}
                                        </p>
                                    </div>
                                )
                            )}

                            {/* adjustments footer */}
                            {remitData.claim_adjustments.length ? (
                                <div
                                    className="flex items-center justify-between w-full border-[1px] border-strokelight border-t-0 rounded-b-r8 p-12
                    text-sm leading-8 gap-24 font-bold
                    hover:bg-secondary-light
                    "
                                >
                                    <p className="w-[48px]" />
                                    <p className="w-[392px]" />

                                    <p className="w-[392px]">Total: </p>
                                    <p className="w-[120px]">
                                        {showMoneyInAppFormat(
                                            remitData.claim_adjustments.reduce(
                                                (acc, item) =>
                                                    acc + item.amount,
                                                0
                                            )
                                        )}
                                    </p>
                                    <p className="w-[120px]">
                                        {remitData.claim_adjustments.reduce(
                                            (acc, item) => acc + item.quantity,
                                            0
                                        )}
                                    </p>
                                </div>
                            ) : null}
                        </Card>
                    )}
            </div>

            {/* EOB */}
            {remitData && (
                <Card classNames="p-24 overflow-x-auto max-w-full mt-32">
                    <p className="text-sm font-bold" id="#eob-section">
                        EOB
                    </p>
                    {singleRemitEOB.isLoading && (
                        <Skeleton
                            className="w-full h-[500px]"
                            containerTestId="remit-eob-loader"
                        />
                    )}
                    {singleRemitEOB.error && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg="Cannot display remit EOB at this time. Please try again later"
                            cardClass="mt-32"
                        />
                    )}
                    {singleRemitEOB.data?.data && (
                        <div className="p-8 bg-secondary-light rounded-r12 border border-[rgba(231,_231,_231,_0.4)] flex justify-center">
                            <Document
                                error={
                                    <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                                        Could not load pdf at this time
                                    </span>
                                }
                                loading={
                                    <span className=" text-sm flex items-center justify-center font-medium h-12">
                                        Loading pdf..
                                    </span>
                                }
                                onLoadSuccess={({ numPages: nextNumPages }) => {
                                    setEOBNumPages(nextNumPages);
                                    setEOBPageNumber(1);
                                }}
                                onItemClick={({
                                    pageNumber: itemPageNumber,
                                }) => {
                                    setEOBPageNumber(Number(itemPageNumber));
                                }}
                                file={`data:image/png;base64,${singleRemitEOB.data?.data}`}
                            >
                                <Page
                                    pageNumber={EOBpageNumber}
                                    height={550}
                                    scale={scale}
                                    className="!w-full"
                                />
                                <div className="flex justify-center items-center mt-6">
                                    <Button
                                        variant="normal"
                                        onClick={zoomIn}
                                        disabled={scale === 3}
                                    >
                                        <ZoomInIcon />
                                    </Button>

                                    <Button
                                        variant="normal"
                                        onClick={zoomOut}
                                        disabled={scale === 1}
                                    >
                                        <ZoomOutIcon />
                                    </Button>
                                </div>

                                {EOBNumPages && EOBNumPages > 1 && (
                                    <Pagination
                                        totalCount={EOBNumPages}
                                        totalPages={EOBNumPages}
                                        currentPage={EOBpageNumber}
                                        onPageChange={(page) =>
                                            setEOBPageNumber(page)
                                        }
                                        pageSize={1}
                                        isCentered
                                    />
                                )}
                            </Document>
                        </div>
                    )}
                </Card>
            )}

            {/* Remit files */}
            {remitData && (
                <div className="w-full bg-white rounded-r8 border border-strokedark mt-32">
                    <div className="flex flex-col gap-y-12 items-start min-[500px]:flex-row min-[500px]:justify-between min-[500px]:items-center px-24 py-[29px]  border-b border-b-stroke-divider">
                        <h3 className="text-base font-bold">Documents</h3>
                        <Button
                            onClick={() => setDocumentDialogInView("add-doc")}
                            disabled={
                                singleRemitFiles.isLoading ||
                                Boolean(singleRemitFiles.error)
                            }
                        >
                            Upload document
                        </Button>
                    </div>
                    {singleRemitFiles.isLoading && (
                        <div className="p-24 grid gap-8 grid-cols-[repeat(auto-fill,minmax(262px,1fr))]">
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                            <Skeleton
                                className="h-[100px]"
                                containerTestId="remit-doc-loader"
                            />
                        </div>
                    )}

                    {singleRemitFiles.error && (
                        <ListState
                            stateHelperText="Try reloading this page to solve this issue"
                            emptyMessage={
                                singleRemitFiles.error.response?.data.message ||
                                "Cannot display document at this time"
                            }
                            isError
                        />
                    )}
                    {singleRemitFiles.data && (
                        <>
                            {!singleRemitFiles.data.data && (
                                <ListState
                                    stateHelperText="Remit documents will appear here when added"
                                    emptyMessage="No documents yet"
                                    isError={false}
                                />
                            )}
                            {singleRemitFiles.data.data &&
                                singleRemitFiles.data.data.length > 0 && (
                                    <div className="p-24 grid gap-8 grid-cols-[repeat(auto-fill,minmax(262px,1fr))]">
                                        {singleRemitFiles.data.data.map(
                                            (attachment) => (
                                                <div
                                                    className="flex flex-col gap-y-24 bg-card-bg rounded-r8 p-16"
                                                    key={
                                                        attachment.remittance_attachment_id
                                                    }
                                                >
                                                    <span className="text-sm text-black font-medium text-left">
                                                        {attachment.name}
                                                    </span>

                                                    <div className="flex justify-between items-center">
                                                        <Button
                                                            variant="normal"
                                                            className="p-0"
                                                            onClick={() => {
                                                                setDocumentToDeleteId(
                                                                    attachment.remittance_attachment_id
                                                                );
                                                                setDocumentDialogInView(
                                                                    "delete-doc"
                                                                );
                                                            }}
                                                        >
                                                            <TrashIcon stroke="#1A738D" />
                                                        </Button>
                                                        <Button
                                                            variant="normal"
                                                            onClick={() => {
                                                                setDocumentDialogInView(
                                                                    "view-doc"
                                                                );
                                                                setDocumentInViewId(
                                                                    attachment.remittance_attachment_id
                                                                );
                                                            }}
                                                            className="p-0 text-xs font-semibold text-primary"
                                                        >
                                                            View doc
                                                            <ArrowRightIcon stroke="#1A738D" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
